import styled from 'styled-components';

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;

  & h1 {
    font-size: 52px;
  }

  & img {
    width: 80%;
    margin-top: 32px;
    max-width: 1000px;
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.5), 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    padding: 10px;
    margin: 10px;

    & h1 {
      font-size: 36px;
    }

    & img {
      width: 90%;
      margin-top: 24px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: 480px) {
    & img {
      width: 100%;
      margin-top: 16px;
    }
  }
`;

const TeamLeadContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 12px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TeamExecContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  max-width: 350px;
  padding: 16px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  cursor: pointer;
  margin: auto;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  }

  &:hover .bio {
    opacity: 1;
    transform: translateY(0);
  }

  & img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 12px;
  }

  & h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0px;
  }

  & h6 {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 8px 0;
  }

  @media (max-width: 768px) {
    width: 60%;
    max-width: 90%;
    & img {
      width: 200px;
      height: 200px;
    }

    & h3 {
      font-size: 1.4rem;
    }

    & h6 {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 480px) {
    width: 90%;
    & img {
      width: 150px;
      height: 150px;
    }

    & h3 {
      font-size: 1.2rem;
    }

    & h6 {
      font-size: 0.6rem;
    }
  }
`;

const BioOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 16px;
  text-align: center;
  border-radius: 0 0 8px 8px;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s ease;
  pointer-events: none;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 12px;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
    padding: 10px;
  }
`;

const TeamExec = ({ name, img, bio, title }) => {
  return (
    <TeamExecContainer>
      <img
        src={img}
        alt={name}
      />
      <h3>{name}</h3>
      <h6>{title}</h6>
      <BioOverlay className='bio'>{bio}</BioOverlay>
    </TeamExecContainer>
  );
};

const Team = ({ name, execs, photo }) => {
  return (
    <TeamContainer>
      <h1>{name}</h1>
      <TeamLeadContainer>
        {execs.map((exec, index) => (
          <TeamExec
            key={index}
            {...exec}
          />
        ))}
      </TeamLeadContainer>
      {photo && (
        <img
          src={photo}
          alt={name}
        />
      )}
    </TeamContainer>
  );
};

export default Team;
