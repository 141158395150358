import styled from 'styled-components';

import FullTeamMainImage from '../../assets/Team2024Images/full_team5_edited.jpg';

export const TeamRolesMainImageContainer = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  background-image: url(${FullTeamMainImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
  @media screen and (max-width: 730px) {
    background: #e5b13a;
    height: 100px;
    margin-bottom: 0;
  }
`;

export const TeamRolesMainImageContentContainer = styled.div`
  height: auto;
  width: auto;
`;

export const TeamRolesMainImageContentText = styled.h1`
  margin: 0px 0px 175px 0px;
  color: white;
  font-size: 140px;
  @media screen and (max-width: 730px) {
    color: #002145;
    font-size: 50px;
    margin: 0;
  }
`;
