import styled from 'styled-components';
import ProjectMainImage from '../../assets/ProjectPageImages/ProjectPageMainImage.png';
import ProjectMainImageMobile from '../../assets/ProjectPageImages/ProjectPageMainImageMobile.png';

// IMPORTANT!
// Create Reliable First Image container that stays consistent throughout the site.

export const ProjectMainImageContainer = styled.div`
    height: calc(100vh - 60px);
    width: 100%;
    background-image: url(${ProjectMainImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;

    @media screen and (max-width: 600px) {
        /* background-image: url(${ProjectMainImageMobile}); */
        height: 50vh;
    }
`;

export const ProjectMainImageContentText = styled.h1`
    margin: 0 0 50px;
    color: white;
    font-size: 140px;
    text-decoration: underline;
    text-decoration-color: #E5B13A;

    @media screen and (max-width: 768px) {
        font-size: 80px;
        margin: 0 0 20px;
    }
    @media screen and (max-width: 600px) {
        font-size: 70px;
        margin: 0 0 20px;
    }
`;

export const ProjectTimelineContainer = styled.div`
    display: flex;
    height: 100%;
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    flex-direction: column;
    border-bottom: 12px solid #E5B13A;
`;

export const ProjectContainer = styled.div`
    border-left: 12px solid #E5B13A;
    border-top: 12px solid #E5B13A;
    border-right: 12px solid #E5B13A;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
`;

export const ProjectInfoContainer = styled.div`
    margin: 30px 0 30px;
    padding: 20px;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ProjectDate = styled.h2`
    color: white;
    padding: 0;
    margin: 0;
    font-size: 30px;

    @media screen and (max-width: 650px) {
        font-size: 24px;
    }
    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
`;

export const ProjectName = styled.h1`
    margin: 0;
    font-size: 100px;
    font-weight: 700;
    line-height: 0.9;
    background: -webkit-linear-gradient(#ffffff, #454545);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 650px) {
        font-size: 80px;
    }
    @media screen and (max-width: 600px) {
        font-size: 60px;
    }
    @media screen and (max-width: 500px) {
        font-size: 50px;
    }
    @media screen and (max-width: 380px) {
        font-size: 40px;
    }
`;

export const ProjectSpecsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 10px 0;
    padding-bottom: 25px;
    border-bottom: 1px solid white;

    @media screen and (max-width: 1150px) {
        flex-direction: column;
        overflow: auto;
        width: 100%;
        box-sizing: border-box;
    }
`;

export const ProjectText = styled.p`
    text-align: left;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;

    @media screen and (max-width: 1150px) {
        order: 2;
    }
`;

export const ProjectModel = styled.img`
    width: auto;
    object-fit: scale-down;
    padding: 0 10px;

    @media screen and (max-width: 1150px) {
        order: 1;
        max-height: 250px;
        padding: 0;
    }

`;

export const ProjectImagesContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 10px 0;

    @media screen and (max-width: 650px) {
        justify-content: space-around;
        flex-wrap: wrap;
        align-content: space-around;
    }
`;

export const ProjectGalleryImage = styled.img`
    width: 18%;
    object-fit: cover;

    @media screen and (max-width: 650px) {
        width: 40%;
        margin: 0 0 10px;
    }
`;

export const Br = styled.br`
    @media screen and (max-width: 1150px) {
        display: none;
    }
`;