import React, { useState } from 'react';
import Team from './Team.jsx';
import NavigationBar from '../NavigationBar/index.js';
import Sidebar from '../Sidebar/index.js';
import {
  leadsExecs,
  leadsADX,
  leadsBMS,
  leadsBTM,
  leadsBUS,
  leadsEMB,
  leadsPAS,
  leadsSTC,
  leadsSTG,
  leadsVDX,
} from './leadsData.js';

import {
  TeamRolesMainImageContentContainer,
  TeamRolesMainImageContentText,
  TeamRolesMainImageContainer,
} from './Landing.jsx';

// TO BE MOVED WHEN I ADD images.js file
import execTeamPhoto from '../../assets/Team2024Images/exec_team.jpg';
import busTeamPhoto from '../../assets/Team2024Images/bus_team.jpg';
import embTeamPhoto from '../../assets/Team2024Images/emb_team.jpg';
import vdxTeamPhoto from '../../assets/Team2024Images/vdx_team.jpg';
import btmTeamPhoto from '../../assets/Team2024Images/btm_team.jpg';
import stgTeamPhoto from '../../assets/Team2024Images/stg_team.jpg';
import stcTeamPhoto from '../../assets/Team2024Images/stc_team.jpg';
import pasTeamPhoto from '../../assets/Team2024Images/pas_team.jpg';
import bmsTeamPhoto from '../../assets/Team2024Images/bms_team.jpg';
import adxTeamPhoto from '../../assets/Team2024Images/adx_team.jpg';

const OurTeamPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavigationBar
        isOpen={isOpen}
        toggle={toggle}
        page={'our-team'}
      />
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
      />
      <TeamRolesMainImageContainer>
        <TeamRolesMainImageContentContainer>
          <TeamRolesMainImageContentText>
            Our Team
          </TeamRolesMainImageContentText>
        </TeamRolesMainImageContentContainer>
      </TeamRolesMainImageContainer>

      <Team
        name={'Executives'}
        execs={leadsExecs}
        photo={execTeamPhoto}
      />
      <Team
        name={'Aerodynamics'}
        execs={leadsADX}
        photo={adxTeamPhoto}
      />
      <Team
        name={'Battery Management Systems'}
        execs={leadsBMS}
        photo={bmsTeamPhoto}
      />
      <Team
        name={'Battery Mechanical'}
        execs={leadsBTM}
        photo={btmTeamPhoto}
      />
      <Team
        name={'Business'}
        execs={leadsBUS}
        photo={busTeamPhoto}
      />
      <Team
        name={'Embedded'}
        execs={leadsEMB}
        photo={embTeamPhoto}
      />
      <Team
        name={'Power and Signals'}
        execs={leadsPAS}
        photo={pasTeamPhoto}
      />
      <Team
        name={'Structures'}
        execs={leadsSTC}
        photo={stcTeamPhoto}
      />
      <Team
        name={'Strategy'}
        execs={leadsSTG}
        photo={stgTeamPhoto}
      />
      <Team
        name={'Vehicle Dynamics'}
        execs={leadsVDX}
        photo={vdxTeamPhoto}
      />
    </>
  );
};

export default OurTeamPage;
