import { React, useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './components/HomePage';
import ProjectPage from './components/ProjectPage';
import CompetitionPage from './components/CompetitionPage';
import RecruitmentPage from './components/JoinUsPage';
import SponsorsPage from './components/SponsorsPage';
import OurTeamPage from './components/OurTeamPage';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //Bug Inquiries
  const [contact, setContact] = useState(true);
  function contacts() {
    if (contact) {
      console.log(
        'Please contact admin@ubcsolar.com for any inquiries or bugs on the website!'
      );
      setContact(false);
    }
  }

  return (
    <>
      <Routes>
        {/* Bug Inquiries */}
        {contacts()}
        <Route
          path='/'
          element={<HomePage />}
        />
        <Route
          path='/projects'
          element={<ProjectPage />}
        />
        <Route
          path='/competitions'
          element={<CompetitionPage />}
        />
        <Route
          path='/team-roles'
          element={<OurTeamPage />}
        />
        <Route
          path='/recruitment'
          element={<RecruitmentPage />}
        />
        <Route
          path='/sponsorships'
          element={<SponsorsPage />}
        />
        <Route
          path='*'
          element={<HomePage />}
        />
      </Routes>
    </>
  );
}

export default App;
